<template>
  <!-- TODO: Penser à insérer l lien de detail d'erreur lorsque erreur dans l'analyse pas dispo au 14/06/2023 -->
  <div class="view-header view-header--protocol-grouping" v-if="pageTitle">
    <div class="view-header__header">
      <Pagination />
      <Btn v-if="helperService.userHasPermission('essairapport_oadd')"
      hollow
      color="white"
      class="action-btn"
      icon="plus"
      text="Créer un regroupement"
      :to="{
        name: 'protocolEssaisGroupingAdd',
        params: {
          id: $route.params.id
        }
      }" />
    </div>
    <div class="view-header__footer">
      <BtnBack :to="{ name : 'protocol', id: $route.params.id}" />
      <h1 class="page-title" v-html="pageTitle"></h1>
    </div>
  </div>
  <div class="view-body">
    <Section class="section section--list">
      <List
        :api="list.api"
        :header="list.headers"
        :items="list.col"
        :item-key="list.key"
        :disableCheckbox="true"
      >

        <template v-slot:type="{ item }">
          <template v-if="item.type === 1 ">
            Données brutes
          </template>
          <template v-else >
            Données moyennées
          </template>
        </template>

        <template v-slot:etat="{ item }">
          <MiniTag v-if="item.etat.uid === 'ENCOURS'" :text="item.etat.designation" color="warning" />
          <MiniTag v-else-if="item.etat.uid === 'TERMINE'" :text="item.etat.designation" color="success" />
          <MiniTag v-else :text="item.etat.designation" color="error" />
        </template>

        <template v-slot:details_erreur="{ item }">
          {{ item.details_erreur }}
        </template>

        <template v-slot:creation="{ item }">
          {{ new Date(item?.modification).toLocaleString() }}
        </template>

        <template v-slot:nbr_essais="{ item }">
          {{ item.essais?.length ?? 0 }}
        </template>

        <template v-slot:actions="{item}">
          <ToolDropdown>
            <MenuItem v-if="helperService.userHasPermission('protocole_show')"
              :item-data="{
                label: 'Consulter',
                iconName: 'visibility',
                route: {
                  name: 'grouping',
                  params: {
                    id: $route.params.id,
                    rid: item.id
                  },
                }
              }"
            />

            <MenuItem v-if="helperService.userHasPermission('protocole_show')"
              :item-data="{
                label: 'Supprimer',
                iconName: 'trash-alt',
  /*                   route: {
                  name: 'protocol',
                  params: {
                    id: item.id,
                  },
                }, */
                }"
              @click="openModal('deleteItem', item)"
            />

            <!-- @click="openModal('reviveReport', item)" -->
            <MenuItem
              v-if="helperService.userHasPermission('protocole_show')"
              :item-data="{
                  label: 'Relancer l\'analyse',
                  iconName: 'pie-chart',
              }"
              @click="openModal('reviveReport', item)"
            />

            <!-- @click="downloadFile(item)" -->
            <MenuItem
              v-if="helperService.userHasPermission('protocole_show')"
              :item-data="{
                label: 'Télécharger le rapport',
                iconName: 'download',
              }"
              @click="downloadFile(item)"
            />
          </ToolDropdown>

        </template>
      </List>
    </Section>
  </div>

  <NavigationDropdownProtocol />

  <Modal title="Supprimer le regroupement&nbsp;?"
    :active="modal.deleteItem"
    :data="modalData"
    @modal-close="modal.deleteItem = false">
    <template v-slot:modal-body>
      <p>
        Voulez vous vraiment supprimer le regroupement&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler"
        @click="modal.deleteItem = false" />
      <Btn text="Supprimer"
        @click="deleteItem(data)"
        icon="trash-alt"
        color="primary" />
    </template>
  </Modal>

  <Modal title="Relancer l'analyse&nbsp;?"
    :active="modal.reviveReport"
    :data="modalData"
    @modal-close="modal.reviveReport = false">
    <template v-slot:modal-body>
      <p>
        Voulez vous vraiment relancer l'analyse&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler"
        @click="modal.reviveReport = false" />
      <Btn text="Relancer"
        @click="revive(data)"
        icon="pie-chart"
        color="primary" />
    </template>
  </Modal>
</template>

<script>
import MiniTag from '@/components/base/MiniTag.vue'
import Modal from '@/components/layout/Modal.vue'
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import List from '@/components/list/List.vue'
import Pagination from '@/components/list/Pagination.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import MenuItem from '@/components/layout/MenuItem.vue'
import BtnBack from '@/components/base/BtnBack.vue'
import NavigationDropdownProtocol
  from '@/views/componentsViews/navigationDropdown/NavigationDropdownProtocol.vue'

export default {
  name: 'ProtocolVersionsView',

  components: {
    MiniTag,
    NavigationDropdownProtocol,
    MenuItem,
    ToolDropdown,
    Pagination,
    BtnBack,
    List,
    Section,
    Btn,
    Modal,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      list: {
        api: {
          endpoint: `protocole/${this.$route.params.id}/regroupement_essai`,
          params: {
            sort: 'id.ASC',
            page: null,
            limit: null,
            filters: 'nom:nn()',
          },
        },
        headers: [
          { label: 'ID' },
          { label: 'Nom', dataField: 'nom' },
          { label: 'Type' },
          { label: 'Etat' },
          { label: '' },
          { label: 'Date' },
          { label: 'Nombre d\'essais' },
        ],
        col: ['id', 'nom', 'type', 'etat', 'details_erreur', 'creation', 'nbr_essais'],
        key: 'id',
      },

      modalData: {},
      modal: {
        reviveReport: false,
        deleteItem: false,
        protocolChoice: false,
        addFile: false,
      },
    }
  },

  mounted() {

  },

  methods: {
    deleteItem(data) {
      this.emitter.emit('open-loader')

      this.fetchService.delete(`protocole/${this.$route.params.id}/regroupement_essai/${data.id}`)
        .then(
          (response) => {
            console.log(response)
            this.emitter.emit('alert', {
              type: 'success',
              content: 'Le regroupement a bien été supprimé.',
            })
            this.emitter.emit('list-refresh')
            this.emitter.emit('close-loader')
          },
          (error) => {
            this.emitter.emit('alert', {
              type: 'error',
              content: error.message,
            })
            this.emitter.emit('close-loader')
          },
        )

      this.modal.deleteItem = false
    },
    revive(data) {
      this.emitter.emit('open-loader')
      this.modal.reviveReport = false

      this.fetchService.patch(`protocole/${this.$route.params.id}/regroupement_essai/${data.id}/statistiques`)
        .then(() => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le rapport est en cours de génération.',
          })
          this.emitter.emit('close-loader')
          this.emitter.emit('list-refresh')
        }, () => {
          this.emitter.emit('close-loader')
          this.emitter.emit('list-refresh')
        })
    },
    downloadFile(item) {
      this.emitter.emit('open-loader')
      const fileName = `regroupement_${item.id}`
      this.fileService.downloadFile(
        `utilitaire/media/${item.media.jeton}.telecharger`, fileName, 'xls',
      ).then(() => {
        this.emitter.emit('close-loader')
      })
    },
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },
  },
}
</script>

<style scoped>
</style>
